import axios from 'axios';

export default (event, finalistId) => ({
  showFullDescription: false,
  modalOpened: false,
  acceptedTerms: false,
  voteSuccess: false,
  responseMessage: null,

  formData: {
    event_user_id: finalistId,
    voter_email: null,
  },

  /**
   * Will enable vote button only if user has entered an email address
   * and accepted the terms.
   *
   * @return {Boolean}
   */
  canEnableVoteButton () {
    return this.formData.voter_email && this.acceptedTerms;
  },

  /**
   * Will tell us if it was a success response and vote got saved.
   *
   * @return {Boolean}
   */
  isSuccess () {
    return this.voteSuccess;
  },

  /**
   * Will tell us if we got any error while saving the vote.
   *
   * @return {Boolean}
   */
  isError () {
    return !this.voteSuccess && this.responseMessage;
  },

  /**
   * Will tell us if form can be displayed.
   *
   * @return {Boolean}
   */
  canDisplayForm () {
    return !this.voteSuccess;
  },

  /**
   * Close the event modal.
   */
  closeModal () {
    if (this.voteSuccess) {
      location.reload();
    } else {
      this.modalOpened = false;
    }
  },

  /**
   * Send user vote.
   */
  sendVote () {
    axios
      .post(route('event.vote.store', { event }), this.formData)
      .then(() => {
        this.voteSuccess = true;
      })
      .catch(({ response }) => {
        this.voteSuccess = false;
        this.responseMessage = response.data.message;
      });
  },
});
