import Alpine from 'alpinejs';

import '@fortawesome/fontawesome-free/js/all.min.js';

import Countdown from './Components/countdown.js';
import EventVote from './Components/Events/vote.js';
import BrokerGuideComponent from './Components/broker-guide.js';
import NotificationCardComponent from './Components/notification-card.js';
import ExpectedIncomeComponent from './Components/Calculators/expected-income.js';
import CostOfPurchaseComponent from './Components/Calculators/cost-of-purchase.js';
import ExpectedInvestmentComponent from './Components/Calculators/expected-investment.js';
import ProbabilityOfFindingPortfolioComponent from './Components/Calculators/probability-of-finding-portfolio.js';

Alpine.data('Countdown', Countdown);
Alpine.data('EventVote', EventVote);
Alpine.data('BrokerGuideComponent', BrokerGuideComponent);
Alpine.data('ExpectedIncomeComponent', ExpectedIncomeComponent);
Alpine.data('CostOfPurchaseComponent', CostOfPurchaseComponent);
Alpine.data('NotificationCardComponent', NotificationCardComponent);
Alpine.data('ExpectedInvestmentComponent', ExpectedInvestmentComponent);
Alpine.data('ProbabilityOfFindingPortfolioComponent', ProbabilityOfFindingPortfolioComponent);

window.Alpine = Alpine;

Alpine.start();

import _ from 'lodash';
window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import '@splidejs/splide/css';
import Splide from '@splidejs/splide';

window.Splide = Splide;

import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
